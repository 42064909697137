<template>
  <div class="contactSupport pad--all-lg origin">
    <h2>Contact support</h2>
    
    <p class="abs--center-right">
      {{success || error}}
    </p>
    <p class="ofst--top-md">
      <em>
        All fields are required
      </em>
    </p>
    <form @submit.stop.prevent="handleSubmit" class="ofst--top-sm">
      <ul class="rst--list">
        <li class="row--md">
          <InputText v-model="ticket.subject" @ready="(input) => input.focus()" class="flx-fill">Subject</InputText>
        </li>
        <li class="row--md">
          <InputEmail v-model="ticket.email" class="flx-fill">Email</InputEmail>
        </li>
        <li class="row--md">
          <InputTextarea v-model="ticket.description" class="contactSupport-description rst--textarea fll--parent-w">Description</InputTextarea>
        </li>
      </ul>
      <ul class="rst--list flx ofst--top-md">
        <li class="flx-end">
          <button type="button" class="button--outline rst--button" @click.stop.prevent="$emit('close')">Cancel</button>
        </li>
        <li class="ofst--left-sm">
          <button type="submit" class="contactSupport-submitButton button--solid rst--button origin" :disabled="submitDisabled">
            <span v-if="!loading">Send</span>
            <Loader v-else width="2rem" height="2rem" class="abs--center-center" />
          </button>
        </li>
      </ul>
    </form>
    <div v-if="success" class="contactSupport-success abs--top-left abs--bottom-right origin">
      <div class="abs--center-center typ--center fll--parent-w pad--all-lg  ">
        <h4>Thank you for your contact us.</h4>
        <p class="ofst--top-md">
          A member of our Customer Support staff will respond to your question shortly.
        </p>
        <button type="button" @click="$emit('close')" class="new-createButton button--solid rst--button origin ofst--top-lg">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from './parts/input-text.vue';
import InputEmail from './parts/input-email.vue';
import InputTextarea from './parts/input-textarea.vue';
import Loader from '@/components/parts/loader.vue';
import {isValidEmail} from '../lib/validateEmail'
import {minimumTimeout} from '@/lib/minimumTimeout.js'

export default {
  data: () => ({
    ticket: {
      subject: '',
      email: '',
      description: '',
    },
    loading: false,
    error: null,
    success: false,
  }),
  components: { InputText, InputEmail, InputTextarea, Loader },
  computed: {
    submitDisabled () {
      return this.ticket.subject && this.ticket.email && this.ticket.description && isValidEmail(this.ticket.email)
    }
  },
  methods: {
    handleSubmit () {
      this.loading = true;
      const timeout = minimumTimeout(1000);

      let res = (success) => {
        this.success = success
        timeout().then(() => {
          this.loading = false
        })
      };
      let rej = (error) => {
        this.error = error;
        timeout().then(() => {
          this.loading = false
        })
      };
      this.$emit('submit', this.ticket, res, rej)
    },
  }
}
</script>

<style lang="scss" scoped>
.contactSupport {
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.5);
  min-width: 20rem;
  width: calc(100% - 4rem);
  max-width: 28rem;

  &-createButton {
    min-width: 100px;
  }

  &-description {
    height: 10rem;
  }

  &-success {
    background-color: rgba(white, 0.95);
  }

  &-submitButton {
    width: 7rem;
  }
}
</style>