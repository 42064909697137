<template>
  <fieldset class="fieldset rst--fieldset">
    <label class="fieldset-label origin fll--parent-h">
      <span class="fieldset-label-span abs--top-left ofst--left-xs" :class="{has_content, has_focus }"><slot></slot></span>
      <textarea
          ref="input"
          class="fieldset-textarea rst--input pad--left-sm pad--right-sm fll--parent"
          v-bind="$attrs"
          :value="modelValue"
          v-on="listeners"
      >
      </textarea>
    </label>
  </fieldset>
</template>

<script>
export default {
  props: {
    modelValue: {
        type: [String, Object],
        default: '',
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  mounted () {
    const focus = () => this.$refs.input.focus()
    this.$emit('ready', {focus})
  },
  computed: {
    listeners ()  {
      return {
        ...this.listeners,
        input: this.onInput,
        focus: this.onFocus,
        blur: this.onBlur
      }
    },
    has_content () {
      return !!this.modelValue
    },
  },
  data: () => ({
    has_focus: false
  }),
  methods: {
    onInput (e) {
      this.$emit('update:modelValue', e.target.value)
    },
    onFocus () {
      this.$nextTick(() => this.has_focus = true)
    },
    onBlur () {
      this.$nextTick(() => this.has_focus = false)
    },
    focus() {
      return this.$refs.input.focus();
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldset {
  background: #fff;
  min-height: 5rem;

  &-textarea {
    min-height: 3em;
    font-size: inherit;
    padding: .75rem;
    background: transparent;
    border: solid thin #ddd;
    border-radius: .25rem;
    
    resize: none;
    font-family: inherit;

    &:focus {
      outline: 0;
      box-shadow: 0;
    }
  }

  &-label {
    display: block;

    &-span {
      pointer-events: none;
      transition: transform .3s ease-in-out;
      transform-origin: left center;
      background-color: white;
      display: inline-block;
      padding: 0 .5em;
      transform: scale(1) translate(0, .5rem);
      

      &.has_content,
      &.has_focus {
        transform: scale(0.75) translate(0, -.75rem);
        color: #333;
      }
    }
  }

  ::v-deep + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>