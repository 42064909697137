<template>
  <div class="app fll--viewport origin">
    <Canvas class="app-canvas" :class="{is_disabled}" @action="handleAction" />
    <header class="app-header abs--top-left abs--top-right origin">
      <Tabs v-show="hasFlow" :class="{is_disabled}" v-model="tabs" @open="openFlow" @action="handleAction" class="app-header-tabs abs--bottom-left abs--bottom-right" />
      <Menu :class="{is_disabled}" :disabledList="disabledList" class="app-header-menu abs--top-left abs--top-right" @action="handleAction" />
    </header>
    <Properties v-if="false" class="app-properties fxd--bottom-right" />
    <New v-if="currentState === states.NEW" @close="stateKey = states.DEFAULT" @submit="newFlow" class="fxd--center-center" />
    <Open v-if="currentState === states.OPEN" :value="allFlows" @select="openFlow" @close="stateKey = states.DEFAULT" @action="handleAction" class="fxd--center-center" />
    <SaveAs v-if="currentState === states.SAVE_AS" :curretFlowName="flow.name"  @close="stateKey = states.DEFAULT" @submit="saveAs" class="fxd--center-center" />
    <Settings v-if="currentState === states.SETTINGS" v-model="userProfile" @updateProfile="updateProfile" class="fxd--center-center" @close="stateKey = states.DEFAULT" />
    <Loader v-if="currentState === states.LOADING" class="fxd--center-center" width="3rem" height="3rem" />
    <ShareLink v-if="currentState === states.SHARE_SECURE_LINK" @close="stateKey = states.DEFAULT" :shareLink="shareLink" class="fxd--center-center" />
    <ShareLink v-if="currentState === states.SHARE_PUBLIC_LINK" @close="stateKey = states.DEFAULT" :shareLink="shareLink" class="fxd--center-center" />
    <ShareLink v-if="currentState === states.CREATE_SURVEY" @close="stateKey = states.DEFAULT" :shareLink="surveyLink" class="fxd--center-center" />
    <ContactSupport v-if="currentState === states.CONTACT_SUPPORT" @close="stateKey = states.DEFAULT" @submit="createSupportTicket" class="fxd--center-center" />
    <p v-if="hasFlow" class="subheading typ--iii-1 abs--bottom-left ofst--left-md ofst--bottom-md">
      {{flow.name}} &mdash; Created: {{ dateFormat(flow.created, 'MM/dd/yyyy') }}
    </p>
    <Poppers />
  </div>
</template>

<script>
import Properties from '@/views/Properties';
import Canvas from '../components/Canvas'
import ShareLink from '../components/ShareLink'
import Menu from '../components/Menu'
import New from '../components/New.vue'
import Open from '../components/Open.vue'
import Settings from '../components/Settings.vue'
import SaveAs from '../components/SaveAs.vue'
import Tabs from '@/components/Tabs.vue'
import dateFormat from "date-fns/format";
import isEmpty from 'lodash/isEmpty'
import Loader from '@/components/parts/loader'
import ContactSupport from '@/components/ContactSupport.vue';
import {minimumTimeout} from '@/lib/minimumTimeout'
import Poppers from '@/components/Popper/poppers';

export default {
  components: {Canvas, ContactSupport,  Loader, Menu, New, Open, Poppers, Properties, SaveAs, ShareLink, Settings, Tabs},
  data: () => ({
    states: {
      DEFAULT: 'DEFAULT',
      NEW: 'NEW',
      OPEN: 'OPEN',
      SAVE_AS: 'SAVE_AS',
      SETTINGS: 'SETTINGS',
      LOADING: 'LOADING',
      SHARE_SECURE_LINK: 'SHARE_SECURE_LINK',
      SHARE_PUBLIC_LINK: 'SHARE_PUBLIC_LINK',
      CREATE_SURVEY: 'CREATE_SURVEY',
      CONTACT_SUPPORT: 'CONTACT_SUPPORT'
    },
    stateKey: 'DEFAULT',
    disabledList: ['pairing', 'create_survey']
  }),
  computed: {
    user () {
      return this.$store.state.auth.user;
    },
    userProfile: {
      get() {
        return this.$store.state.user.profile
      }
    },
    readOnly () {
      return this.$store.getters['flow/readOnly']
    },
    tabs () {
      return this.$store.state.flow.tabs
    },
    currentState () {
      return this.stateKey in this.states ? this.states[this.stateKey] : null
    },
    allFlows () {
      return this.$store.state.flow.allFlows
    },
    flowUid () {
      return this.$store.state.flow.flowUid;
    },
    flow () {
      let flow = this.$store.getters['flow/flow'];
      return flow ? flow : {}
    },
    hasFlow () {
      return this.flow && !isEmpty(this.flow)
    },
    is_disabled () {
      return !(this.currentState === this.states.DEFAULT)
    },
    shareLink () {
      return this.$store.state.link.share
    },
    surveyLink () {
      return this.$store.state.link.survey
    }
  },
  mounted () {
    const uid = this.$route.params.uid;
    this.$store.dispatch('auth/checkSignedIn').then(() => {
      this.$store.dispatch('user/getCurrentProfile');
      this.$store.dispatch('flow/getAllFlows').then(() => {
        if (uid && uid in this.allFlows) {
          let timeout = minimumTimeout();
          this.stateKey = this.states.LOADING;
          this.$store.commit('flow/setFlowUid', uid);
          this.$store.dispatch('flow/getAllNodes', uid).then(() => {
            timeout().then(() => {
              this.$store.commit('setSelected', [])
              this.stateKey = this.states.DEFAULT;
            })
          })
        } else {
          if (!this.flowUid) {
            if (isEmpty(this.allFlows)) {
              this.stateKey = this.states.NEW;
            } else {
              this.stateKey = this.states.OPEN;
            }
            this.setDiabled('insert');
            this.setDiabled('save_as');
            this.setDiabled('save_to_desktop');
            this.setDiabled('share_secure_link');
            this.setDiabled('share_public_link');
          }
        }
      }).catch(err => console.error(err))
    })
  },
  watch: {
    flowUid (uid) {
      if (!uid) {
        this.setDiabled('insert');
        this.setDiabled('save_as');
        this.setDiabled('save_to_desktop');
        this.setDiabled('share');
        this.setDiabled('share_secure_link');
        this.setDiabled('share_public_link');

      } else {
        this.$router.push({ name: 'App', params: { uid } });
        this.$store.commit('flow/addRecentFlow', uid);
        this.removeDisabled('save_as');
        this.removeDisabled('save_to_desktop');
        if (this.readOnly) {
          this.setDiabled('insert');
          this.setDiabled('share');
        } else {
          this.removeDisabled('insert');
          this.removeDisabled('share_secure_link');
          this.removeDisabled('share_public_link');
        }
      }
    }
  },
  methods: {
    dateFormat,
    setDiabled (name) {
      if (this.disabledList.includes(name)) {
        return this.disabledList
      } else {
        return this.disabledList = [...this.disabledList, name]
      }
    },
    removeDisabled(name) {
      let index = this.disabledList.indexOf(name);
      if (index >= 0) {
        this.disabledList.splice(index, 1);
        return this.disabledList
      }
    },
    handleAction (action, params) {
      switch(action) {
        case 'new': 
          this.stateKey = this.states.NEW;
        break;

        case 'open':
          this.$store.dispatch('flow/getAllFlows');
          this.stateKey = this.states.OPEN
          break;

        case 'upload':
          if ('nodes' in params) {
            this.$store.dispatch('flow/loadFlow', params.nodes);
          }
          break;

        case 'save_to_desktop':
          this.$store.dispatch('flow/saveToDesktop');
          break;

        case 'save_as':
          this.stateKey = this.states.SAVE_AS
          break;

        case 'undo':
          this.$store.dispatch('flow/undo')
          break;

        case 'redo':
          this.$store.dispatch('flow/redo')
          break;

        case 'insert_node':
          var y = window.innerHeight/2 - 75;
          var x = window.innerWidth/2 - 40;
          this.$store.dispatch('flow/createNode', {x, y})
          break;

        case 'settings':
          this.stateKey = this.states.SETTINGS
          break;

        case 'share_secure_link':
          this.$store.dispatch('link/createSecureLink', this.flowUid).then(() => {
            this.stateKey = this.states.SHARE_SECURE_LINK;
          });
          break;

        case 'share_public_link':
          this.$store.dispatch('link/createPublicLink', this.flowUid).then(() => {
            this.stateKey = this.states.SHARE_PUBLIC_LINK;
          });
          break;

        case 'create_survey':
          this.$store.dispatch('link/createSurvey', this.flowUid).then(() => {
            this.stateKey = this.states.CREATE_SURVEY;
          });
          break;

        case 'pair_start':
          this.$store.dispatch('room/createRoom')
          break;

        case 'contact_support':
          location.href='mailto:floux_feedback@icloud.com?subject=Support request'
          // this.stateKey = this.states.CONTACT_SUPPORT
          break;
      }
    },
    newFlow({name}, res, rej) {
      var y = window.innerHeight/2 - 75;
      var x = window.innerWidth/2 - 40;
      this.$store.dispatch('flow/newFlow', {name, x, y}).then(() => {
        res(`Creating ${name}...`);
      }).catch(() => {
        rej('There was an error, please try again');
      })
    },
    saveAs ({name}) {
      this.$store.dispatch('flow/saveFlowAs', name).then(() => {
        this.stateKey = this.states.DEFAULT;
      }).catch(console.error)
    },
    openFlow(uid) {
      const timeout = minimumTimeout();
      this.stateKey = this.states.LOADING
      this.$store.commit('flow/setFlowUid', uid);
      this.$store.dispatch('flow/getAllNodes', uid).then(() => {
        timeout().then(() => {
          this.$store.commit('setSelected', []);
          this.stateKey = this.states.DEFAULT;
        })
      })
    },
    createSupportTicket (ticket, res, rej) {
      this.$store.dispatch('support/createTicket', ticket).then(res).catch(rej)
    },
    updateProfile (userProfile, res, rej) {
      this.$store.dispatch('user/updateProfile', userProfile).then(() => {
        res()
      }).catch(rej)
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  // background-image: url('../assets/app/ferdinand-stohr-iW1WzbuWMcA-unsplash.png');
  background-size: cover;

  &-canvas {
    width: 100vw;
    height: calc(100vh - 4rem);
    top: 4rem;
  }

  &-header {
    // background-color: rgba(255,255,255, 0.9);
    height: 3.75rem;

    &-tabs {
      transform: translate(1.5rem, 0);
      max-width: calc(100% - 3rem);
    }
  }

  &-properties {
    width: 16rem;
    height: calc(100% - 3.75rem);
    background: #ddd;
  }
}

.is_disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>